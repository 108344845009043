<template>
  <main class="dgrid-body">
    <h2 class="view-title">{{ $t('categorie_titolo_pagina') }}</h2>
    <p class="text--subtitle view-subtitle">{{ $t('categorie_descrizione_pagina') }}</p>
    <div class="view-body dgrid-container dgrid-minw-wrap">

      <template v-if="!topics.length">
        <base-cg-topic 
          v-for="i in 12"
          :key="i"
          skeleton
        />        
      </template>

      <template v-else>
        <base-cg-topic 
          v-for="t in topics"
          :key="t.id"
          :item="t"
          ignore-max-width
          @clicked="$router.push({name: 'explore', query: {topic: t.name}})"
        />        
      </template>

    </div>

    <div class="view-bottom-margin"></div>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import collages from '@js/collages.js';

export default {
  name: 'Stories',
  computed: {
    ...mapState('cms', ['topics']),
  },
  methods: {
    ...mapActions('cms', ['getList']),
    goTo(name) {
      this.$router.push({name: 'explore', query: {topic: name}})
    }
  },
  created() {
    this.getList('topics');
  }
}
</script>
<style lang="scss">
  
  #topics.view {
    --dgrid-min-width: 20rem;
  }

  @media (max-width: 720px) {
    #topics.view {
      --dgrid-gap: var(--s3);  
    }
  }

</style>